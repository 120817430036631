<script setup>
import { ref, toRefs, onMounted, computed, watch, onUnmounted } from 'vue';

import Popper from 'popper.js';

import DsIconButton from './DsIconButton.vue';

import T from './DsFeatureTip.i18n.js';

import "icons/close.svg";

const States = Object.freeze({
    SHOWING: Symbol('showing'),
    SHOWN:   Symbol('shown'),
    HIDING:  Symbol('hiding'),
    HIDDEN:  Symbol('hidden'),
});

const props = defineProps({
    target: {    // selector or element or Vue component
        type: [String, HTMLElement, Object],
        required: true,
    },
    header: {
        type: String,
        required: true,
    },
    headerTag: {
        type: String,
        required: true,
    },
    button: {
        type: Object,
        required: false,
        default: null,
    },
    autoShow: {
        type: Boolean,
        required: false,
        default: false,
    },
    position: {
        type: String,
        required: false,
        default: 'top',
        validator: (x) => ['top', 'right', 'bottom', 'left'].includes(x),
    },
    flipBehavior: {
        type: Boolean,
        default: true,
    },
});
const emit = defineEmits(['button-click', 'showing', 'shown', 'hiding', 'hidden']);

const { target, autoShow, position } = toRefs(props);
const state = ref(States.HIDDEN);

const targetElem = computed(() => {
    if (target.value instanceof HTMLElement) {
        return target.value;
    } else if (target.value.$el) {
        return target.value.$el;
    } else {
        const res = document.querySelector(target.value);
        if (!res) {
            throw new Error(`Could not find target by selector '${target.value}'`);
        }
        return res;
    }
});

const elem = ref();
const arrow = ref();
const effectivePosition = ref(position.value);

let popper;

const classes = computed(() => {
    return [
        'feature-tip',
        `feature-tip--${effectivePosition.value}`,
        `feature-tip--${state.value.description}`,
    ];
});

function _stateChange(startState, endState) {
    if (state.value === startState || state.value === endState) {
        return Promise.resolve();
    }
    return new Promise((resolve) => {
        elem.value.addEventListener('transitionend', () => {
            state.value = endState;
            resolve();
        }, { once: true });
        state.value = startState;
    });
}

function show() {
    return _stateChange(States.SHOWING, States.SHOWN);
}

function hide() {
    return _stateChange(States.HIDING, States.HIDDEN);
}

watch(
    () => state.value,
    (newState) => emit(newState.description),
);
watch(
    () => position.value,
    (newPosition) => {
        effectivePosition.value = newPosition;
        popper.options.placement = newPosition;
        popper.scheduleUpdate();
    },
);

function setupPopper() {
    if (popper) {
        popper.destroy();
    }

    let popperOptions = {
        placement: effectivePosition.value,
        modifiers: {
            arrow: {
                element: arrow.value,
            },
        },
        onUpdate: (data) => {
            effectivePosition.value = data.placement;
        },
        onCreate: (data) => {
            effectivePosition.value = data.placement;
            if (autoShow.value) {
                show();
            }
        },
    };

    if (props.flipBehavior) {
        popperOptions.modifiers = {
            flip: {
                behavior: [
                    'left', 'left-start', 'left-end',
                    'right', 'right-start', 'right-end',
                    'top', 'top-start', 'top-end',
                    'bottom', 'bottom-start', 'bottom-end',
                ]
            }
        };
    }

    popper = new Popper(targetElem.value, elem.value, popperOptions);
}

onMounted(setupPopper);
watch(
    () => target.value,
    setupPopper,
);

onUnmounted(() => {
    popper.destroy();
    popper = null;
});

defineExpose({ show, hide });
</script>

<template>
    <div
        :class="classes"
        ref="elem"
        :aria-hidden="state === States.HIDING || state === States.HIDDEN ? 'true' : 'false'"
    >
        <div class="feature-tip__outer">
            <div class="feature-tip__arrow" ref="arrow" />
            <DsIconButton
                class="feature-tip__close"
                type="secondary"
                icon-name="close"
                :label="T.close()"
                size="sm"
                @click="hide"
                button-type="button"
            />
            <div class="feature-tip__inner">
                <div class="heading-tag">
                    {{ headerTag }}
                </div>
                <h5 class="mb-0">
                    {{ header }}
                </h5>
                <div class="feature-tip__content">
                    <slot />
                </div>
                <Component
                    class="btn btn-sm btn-narrow btn-secondary feature-tip__button"
                    :is="button.href ? 'a' : 'button'"
                    :href="button.href"
                    @click="$emit('button-click', $event)"
                    :type="button.href ? null : 'button'"
                    v-if="button"
                >
                    {{ button.label }}
                </Component>
            </div>
        </div>
    </div>
</template>
